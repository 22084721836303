@tailwind components;

@layer components {
    .btnBasic {
        @apply inline-block rounded-full focus:outline-none focus:ring active:text-opacity-75 hover:shadow-md;
    }

    /**************** Transitions ****************/

    .basicTransition {
        @apply transition duration-300 ease-in-out;
    }
}
