@tailwind components;

@layer components {
    .stroke {
      -webkit-text-stroke: 2px #E48586;

  }
  .stroke1 {
    -webkit-text-stroke: 2px #916DB3;
  }
}

  